import React from 'react';
import BannerSectionStyle3 from '../Section/BannerSection/BannerSectionStyle3';
import BannerSectionStyle4 from '../Section/BannerSection/BannerSectionStyle4';
import Section from '../Section';
import DepartmentSectionStyle2 from '../Section/DepartmentSection/DepartmentSectionStyle2';
import FeaturesSectionStyle2 from '../Section/FeaturesSection/FeaturesSectionStyle2';
import FunFactSection from '../Section/FunFactSection';
import TeamSection from '../Section/TeamSection';
import GallerySection from '../Section/GallerySection';
import AwardSectionStyle2 from '../Section/AwardSection/AwardSectionStyle2';
import { pageTitle } from '../../helpers/PageTitle';
const departmentData = [
  {
    title: '24/7 Support',
    subTitle:
      'On-call medical professionals available 24/7',
    iconUrl: '/images/icons/calendar_white.svg',
    href: '/departments/department-details',
  },
  {
    title: 'Home services',
    subTitle:
      'Home service vitamin drips for hydration and wellness',
    iconUrl: '/images/icons/calendar_white.svg',
    href: '/departments/department-details',
  },
  {
    title: 'Delivery',
    subTitle:
      'Emergency pharmaceutical delivery to your location',
    iconUrl: '/images/icons/calendar_white.svg',
    href: '/departments/department-details',
  },
 
];

const featureListData = [
  {
    title: 'Confident',
    subTitle:
      'Our experienced team is commited to your safety and well-being.',
    iconUrl: 'images/icons/professional.svg',
  },
  {
    title: 'Caring',
    subTitle:
      'We priotize your health and comfort every step of the way.',
    iconUrl: 'images/icons/comprehensive.svg',
  },
  {
    title: 'Empathetic',
    subTitle:
      'We understand your concerns and tailor our services to meet your needs.',
    iconUrl: 'images/icons/patient.svg',
  },
  {
    title: 'Modern',
    subTitle:
      'Utilizing advanced technology to provide seamless healthcare solutions.',
    iconUrl: 'images/icons/facilities.svg',
  },
  {
    title: 'Trustworthy',
    subTitle:
      'Building trust through transparency, reliability, and exceptional service.',
    iconUrl: 'images/icons/facilities.svg',
  },
 
];

const journey = [
  {
    title: 'Your journey, Our priority',
    subTitle:
      'Experience worry-free travel with Global Guard Travel Solutions Solutions by youur side.<br/> Subscribe today to embark on your next adventure with confidence!.',
    iconUrl: 'images/icons/facilities.svg',
  },
]

const funFactData = [
  { number: '20+', title: 'Years of experience' },
  { number: '95%', title: 'Patient satisfaction rating' },
  { number: '5000+', title: 'Patients served annually' },
  { number: '10+', title: 'Healthcare providers on staff' },
  { number: '22+', title: 'Convenient locations in the area' },
];

const teamData = [
  {
    imgUrl: 'images2/about/doctor_1(1).png',
    name: 'Dr. James Lee, MD',
    designation: 'Head of Cardiologist',
    description:
      'With expertise in managing complex heart conditions and performing advanced cardiac procedures',
    social: [
      { icon: 'fa6-brands:facebook-f', href: '/about' },
      { icon: 'fa6-brands:linkedin-in', href: '/about' },
      { icon: 'fa6-brands:twitter', href: '/about' },
    ],
  },
  {
    imgUrl: 'images2/about/doctor_2(1).png',
    name: 'Dr. John Smith, MD',
    designation: 'Emergency Medicine Physician',
    description:
      'With expertise in treating acute illnesses and injuries in medicine physician',
    social: [
      { icon: 'fa6-brands:facebook-f', href: '/about' },
      { icon: 'fa6-brands:linkedin-in', href: '/about' },
      { icon: 'fa6-brands:twitter', href: '/about' },
    ],
  },
  {
    imgUrl: 'images2/about/doctor_3(1).png',
    name: 'Dr. Susan Bones, MD',
    designation: 'Board-certified Pediatrician',
    description:
      'With experience in managing complex medical conditions in children',
    social: [
      { icon: 'fa6-brands:facebook-f', href: '/about' },
      { icon: 'fa6-brands:linkedin-in', href: '/about' },
      { icon: 'fa6-brands:twitter', href: '/about' },
    ],
  },
];

const galleryData = [
  { imgUrl: '/images2/about/portfolio_2_lg.jpeg' },
  { imgUrl: '/images2/about/portfolio_3_lg.jpeg' },
  { imgUrl: '/images2/about/portfolio_1_lg.jpeg' },
  { imgUrl: '/images2/about/portfolio_4_lg.jpeg' },
  { imgUrl: '/images2/about/portfolio_5_lg.jpeg' },
];
const awardData = [
  {
    iconUrl: '/images/icons/award.svg',
    title: 'Malcolm Baldrige National Quality Award',
  },
  { iconUrl: '/images/icons/award.svg', title: 'HIMSS Davies Award' },
  {
    iconUrl: '/images/icons/award.svg',
    title: 'Healthgrades National’s Best Hospital',
  },
  {
    iconUrl: '/images/icons/award.svg',
    title: 'Joint Commission Gold Seal of Approval',
  },
];

export default function About() {
  pageTitle('About');
  return (
    <>
      <BannerSectionStyle3
        bgUrl="/images/about/banner_bg.svg"
        imgUrl="/images2/about/banner_img(5).png"
        title="Welcome to <br />Global Guard Travel Solutions Medical & Healthcare Center"
        subTitle="Your Partner in Health and Wellness"
      />
      <Section topMd={200} topLg={150} topXl={110}>
        <DepartmentSectionStyle2
          sectionTitle="Provides Our Best Services"
          sectionTitleUp="SERVICES"
          data={departmentData}
        />
      </Section>
      <Section topMd={175} topLg={125} topXl={85} bottomMd={100} bottomLg={110}>
        <FeaturesSectionStyle2
          sectionTitle="Why Choose Us"
          imgUrl="images2/about/why_choose_us.jpeg"
          data={featureListData}
        />
      </Section>

      <Section topMd={200} topLg={150} topXl={110}>
        <DepartmentSectionStyle2
          sectionTitle="Your Journey, Our Priority"
          sectionTitleUp="Global Guard Travel Solutions"
          data={journey}
        />
      </Section>

      <Section>
        <FunFactSection
          bgUrl="images/about/fun_fact_bg.jpeg"
          data={funFactData}
        />
      </Section>
      <Section topMd={190} topLg={145} topXl={105}>
        <TeamSection
          sectionTitle="Experts Doctor"
          sectionTitleUp="MEET OUR"
          data={teamData}
        />
      </Section>
      <Section topMd={170} topLg={120} topXl={80}>
        <GallerySection
          sectionTitle="Our Facilities and <br />Latest Activities"
          sectionTitleUp="HAVE A LOOK AT"
          data={galleryData}
        />
      </Section>
      <Section
        topMd={190}
        topLg={145}
        topXl={105}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <AwardSectionStyle2
          sectionTitle="Winning Awards and <br />Recognition"
          sectionTitleUp="AWARDS"
          sectionSubTitle="We have been recognized for our commitment to <br />excellence in healthcare."
          data={awardData}
        />
      </Section>
      <Section className="cs_footer_margin_0">
        <BannerSectionStyle4
          bgUrl="images2/departments/banner_bg_2.jpeg"
          title="Don’t Let Your Health <br />Take a Backseat!"
          subTitle="Schedule an appointment with one of our experienced <br />medical professionals today!"
          center
        />
      </Section>
    </>
  );
}
